<template lang="pug">
	.loader
		.spiner
		p Your connection is being set up. This might take up to a minute. Please wait.
		
</template>
<script>
	export default {
		name: 'Loader'
	}
</script>