<template lang="pug">
	.error
		p
			b Your account data is currently unavailable. 
				br
				| Please contact your INFUSE Client Success Representative for further assistance.

		p You can return to the home page 
			router-link(:to="{ name: 'Instruction'}") here
			| .
		
</template>
<script>
	export default {
		name: 'Error'
	}
</script>