<template lang="pug">
footer
	//- .logo-infuse
	//- 	img(src="@/assets/infuse-logo.svg", alt="")

	.main-footer
		.address
			p 2385 NW Executive Center Drive, Suite 100
				br
				| Boca Raton, FL 33431

		.contacts
			a(href="mailto:info@infuse.com").mail info@infuse.com

		a(href="https://infuse.com/about/our-accolades/", target="_blank").awards
			img(src="@/assets/inc-awards.svg", alt="")

		.copy-privacy
			.links
				a(href="https://infuse.com/terms-and-conditions/", target="_blank") Terms & Conditions
				span |
				a(href="https://infuse.com/cookie-policy/", target="_blank") Cookie Policy
				span |
				a(href="https://infuse.com/privacy-policy/", target="_blank") Privacy Policy
			p © 2025 INFUSE All Rights Reserved
			
</template>

<script>
	export default {
		name: 'Footer'
	}
</script>